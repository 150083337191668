// Here you can add other styles

// 테이블 CSS 모음

// Columns 크기는 아래 지정
table {
  border-collapse: collapse;
  thead#smTable th {
    background: #003366;
    color: #fff;
  }
  thead#orderTable {
    background: #ebedef;
  }
  &.orderList {
    thead > tr > th:first-child {
      text-align: start;
      padding-left: 2rem;
    }
    tbody > tr > td:first-child {
      text-align: start;
      padding-left: 2rem;
      background: #ebedef;
    }
    tbody > tr:last-child,
    tbody > tr:last-child td {
      background: #b2ddef;
    }
  }
  &.orderVanList {
    tbody > tr > td:first-child,
    tbody > tr > td:nth-child(4) {
      background: #ebedef;
    }
  }
  tr {
    text-align: center;
  }
  th {
    &.radioButton,
    &.deleteBtn,
    &.files,
    &.checkBox {
      width: 10%;
      max-width: 30px;
      min-width: 20px;
    }
    &.userId,
    &.status,
    &.reply,
    &.orderStatus,
    &.no,
    &.number,
    &.id,
    &.hasReply,
    &.trainingPersonnel,
    &.noticeFiles,
    &.orderItemCount {
      width: 10%;
      max-width: 100px;
      min-width: 90px;
    }
    &.deliveryStatus,
    &.CAT,
    &.POS,
    &.KIOSK,
    &.sum {
      width: 10%;
      max-width: 110px;
      min-width: 90px;
    }
    &.payDate,
    &.createdAt,
    &.updatedAt,
    &.category,
    &.userName,
    &.receiver,
    &.orderDate,
    &.region,
    &.remark,
    &.trainingDate {
      max-width: 200px;
      min-width: 130px;
    }
    &.orderItemPrice,
    &.phoneNumber,
    &.businessName,
    &.orderNum {
      min-width: 160px;
    }
    &.businessAddress,
    &.secessionReason,
    &.categoryKorean,
    &.businessNumber,
    &.distributorName {
      min-width: 180px;
    }
    &.itemName,
    &.announcementTitle,
    &.title {
      width: 100%;
      min-width: 200px;
    }
    &.invoiceNumber,
    &.orderNumber {
      min-width: 200px;
    }
    &.isAuthorized {
      width: 50px;
      min-width: 0;
    }
    &.region {
      width: 20%;
      max-width: 150px;
      min-width: 90px;
    }
    &.poscount,
    &.kioskcount,
    &.sum,
    &.catcount {
      width: 10%;
      max-width: 100px;
      min-width: 90px;
    }
    &.note {
      width: 10%;
      max-width: 120px;
      min-width: 100px;
    }
  }

  td {
    &.totalPrice,
    &.orderItemPrice,
    &.cancelPrice,
    &.exchangePrice {
      text-align: right;
      max-width: 110px;
      min-width: 90px;
    }
  }
}

td {
  cursor: pointer;
  border: 1px solid #d8dbe0;
}

th.small {
  min-width: 70px;
  max-width: 70px;
}

th {
  min-width: 150px;
  max-width: 150px;
  border: 1px solid #d8dbe0;
}

th.large {
  min-width: 300px;
  max-width: 300px;
}

.react-datepicker__triangle {
  position: absolute;
  left: 0 !important;
  transform: translate(240px, 0px);
}

//필수값
.required {
  font-weight: 700;
  &:after {
    content: ' *';
    //    color: red;
  }
}

.modal-scroll {
  max-height: 700px;
  overflow-y: scroll;
}

// 탈퇴 회원 차트 크기
.mallChart {
  width: 600px;
  height: 300px;
}

// 공식몰 주문상태
.orderStatusForm,
.orderInvoiceForm {
  width: 200px;
}

.invoiceNumberBtn {
  width: 100%;
  max-width: 50px;
}

.invoiceSearchBtn {
  width: 100%;
  width: 80px;
}

.dateSearchBtn {
  max-width: 60px;
  min-width: 60px;
}

.ant-picker-dropdown.ant-picker-dropdown-range.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft {
  z-index: 9000;
}
